<template>
  <div class="page">
    <div class="img">
      <img src="./img/success.png" alt="" />
      <div class="message">已经提交成功，请耐心等待工作人员联系您～</div>
      <div class="btn" @click="goBack">返回</div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  name: "safetyShieldApplySuccess",
  methods: {
    goBack() {
      if (window.location.origin == "https://mapi.xydata.cc") {
        this.$router.go(-1);
      } else {
        wx.miniProgram.navigateTo({
          url: `/pages/index/index`,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  .img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    img {
      display: block;
      width: 568px;
      margin: 0 auto;
    }
    .btn {
      width: 550px;
      height: 66px;
      background: linear-gradient(180deg, #72a8ff 0%, #2f7cff 100%);
      border-radius: 10px;
      line-height: 66px;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 62px;
    }
    .message {
      font-size: 30px;
      font-weight: 600;
      color: #307dff;
      white-space: nowrap;
      padding-top: 90px;
      text-align: center;
    }
  }
}
</style>
